import { 
    LOGOUT,
    ADD_DISTRICT,
    GET_DISTRICT,
    UPDATE_DISTRICT,
    GET_ALL_DISTRICTS,
    DELETE_DISTRICT,
    GET_SELECTED_STATES,
} from "../types";

const initialState = {
    allDistricts: null,
    addDistrictResponse: null,
    district: null,
    updateDistrictResponse: null,    
    deleteDistrictResponse: null,
    allSelectedStates: null,
};

export default function district(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_DISTRICTS:
            return {
                ...state,
                allDistricts: payload,
            };
        case ADD_DISTRICT:
            return {
                ...state,
                addDistrictResponse: payload,
            };
        case GET_DISTRICT: 
            return {
                ...state,
                district: payload,
            };
        case UPDATE_DISTRICT:
            return {
                ...state,
                updateDistrictResponse: payload,
            };
        case DELETE_DISTRICT:
            return {
                ...state,
                deleteDistrictResponse: payload,
            };
        case GET_SELECTED_STATES:
            return {
                ...state,
                allSelectedStates: payload,
            };
        default:
            return state;
    }
}


import { 
    LOGOUT,
    ADD_LOCATION,
    GET_LOCATION,
    UPDATE_LOCATION,
    GET_ALL_LOCATIONS,
    DELETE_LOCATION,
    GET_SELECTED_STATES,
    GET_SELECTED_DISTRICTS,
    GET_SELECTED_CITIES,
} from "../types";

const initialState = {
    allLocations: null,
    addLocationResponse: null,
    location: null,
    updateLocationResponse: null,    
    deleteLocationResponse: null,
    allSelectedStates: null,
    allSelectedDistricts: null,
    allSelectedCities: null,
};

export default function location(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_LOCATIONS:
            return {
                ...state,
                allLocations: payload,
            };
        case ADD_LOCATION:
            return {
                ...state,
                addLocationResponse: payload,
            };
        case GET_LOCATION: 
            return {
                ...state,
                location: payload,
            };
        case UPDATE_LOCATION:
            return {
                ...state,
                updateLocationResponse: payload,
            };
        case DELETE_LOCATION:
            return {
                ...state,
                deleteLocationResponse: payload,
            };
        case GET_SELECTED_STATES:
            return {
                ...state,
                allSelectedStates: payload,
            };
        case GET_SELECTED_DISTRICTS:
            return {
                ...state,
                allSelectedDistricts: payload,
            };
        case GET_SELECTED_CITIES:
            return {
                ...state,
                allSelectedCities: payload,
            }
        default:
            return state;
    }
}


import { 
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_LOGIN_ERROR,
    CHANGE_TRANSFER_PASSWORD,
    CHANGE_PASSWORD, 
} from "../types";


const initialState = {
    isAuthenticated: null,
    // loading: true,
    user: null,
    error: null,
    loginResponse: {},
    changeTransferPasswordResponse: null,
    changePasswordResponse: null,
};

export default function auth(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOADED: 
            return {
                ...state,
                isAuthenticated: true,
                // loading: false,
                user: payload,
            };

        case REGISTER_SUCCESS: 
            return {
                ...state,
                // loading: false,
            };

        case LOGIN_SUCCESS: 
            return {
                ...state,
                loginResponse: payload,
                // loading: false,
            };

        case REGISTER_FAIL:
        case AUTH_ERROR:            
        case LOGIN_FAIL:
        
        // eslint-disable-next-line no-fallthrough
        case LOGOUT: 
            localStorage.removeItem('token');
            return {
                ...initialState,
                // loading: false,
                error: payload || null,
            };
        
        case SET_LOGIN_ERROR: 
            return {
                ...state,
                error: payload,
            };
        
        case CHANGE_TRANSFER_PASSWORD: 
            return {
                ...state,
                changeTransferPasswordResponse: payload,
            };

        case CHANGE_PASSWORD: 
            return {
                ...state,
                changePasswordResponse: payload
            };

        default:
            return state;
    }
}
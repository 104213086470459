import { 
    LOGOUT,
    ADD_SOURCE,
    GET_SOURCE,
    UPDATE_SOURCE,
    GET_ALL_SOURCES,
    DELETE_SOURCE,
} from "../types";

const initialState = {
    allSources: null,
    addSourceResponse: null,
    source: null,
    updateSourceResponse: null,    
    deleteSourceResponse: null,
};

export default function source(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_SOURCES:
            return {
                ...state,
                allSources: payload,
            };
        case ADD_SOURCE:
            return {
                ...state,
                addSourceResponse: payload,
            };
        case GET_SOURCE: 
            return {
                ...state,
                source: payload,
            };
        case UPDATE_SOURCE:
            return {
                ...state,
                updateSourceResponse: payload,
            };
        case DELETE_SOURCE:
            return {
                ...state,
                deleteSourceResponse: payload,
            };
        default:
            return state;
    }
}


import { 
    LOGOUT,
    ADD_BRANCH,
    GET_BRANCH,
    UPDATE_BRANCH,
    GET_ALL_BRANCHES,
} from "../types";

const initialState = {
    allBranches: null,
    addBranchResponse: null,
    branch: null,
    updateBranchResponse: null,    
};

export default function branch(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_BRANCHES:
            return {
                ...state,
                allBranches: payload,
            };
        case ADD_BRANCH:
            return {
                ...state,
                addBranchResponse: payload,
            };
        case GET_BRANCH: 
            return {
                ...state,
                branch: payload,
            };
        case UPDATE_BRANCH:
            return {
                ...state,
                updateBranchResponse: payload,
            };
        default:
            return state;
    }
}


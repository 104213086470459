import { 
    LOGOUT,
    ADD_PROPERTY_STATUS,
    GET_PROPERTY_STATUS,
    UPDATE_PROPERTY_STATUS,
    GET_ALL_PROPERTY_STATUS,
    DELETE_PROPERTY_STATUS,
} from "../types";

const initialState = {
    allPropertyStatus: null,
    addPropertyStatusResponse: null,
    propertyStatus: null,
    updatePropertyStatusResponse: null,    
    deletePropertyStatusResponse: null,
};

export default function propertyStatus(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_PROPERTY_STATUS:
            return {
                ...state,
                allPropertyStatus: payload,
            };
        case ADD_PROPERTY_STATUS:
            return {
                ...state,
                addPropertyStatusResponse: payload,
            };
        case GET_PROPERTY_STATUS: 
            return {
                ...state,
                propertyStatus: payload,
            };
        case UPDATE_PROPERTY_STATUS:
            return {
                ...state,
                updatePropertyStatusResponse: payload,
            };
        case DELETE_PROPERTY_STATUS:
            return {
                ...state,
                deletePropertyStatusResponse: payload,
            };
        default:
            return state;
    }
}


import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line consistent-return
const displayMSG = (msgType, message) => {
    let showMSG = '';
    if (msgType === 'success') {
        showMSG =  toast.success(message, {
            position: 'top-right',
            theme: 'colored',
            transition: Slide,
            autoClose: 500,
        });        
    } else if (msgType === 'error') { 
        showMSG =  toast.error(message, {
            position: 'top-right',
            theme: 'colored',
            transition: Slide,
            autoClose: 500,
        });
    } else if (msgType === 'info') { 
        showMSG =  toast.info(message, {
            position: 'top-right',
            theme: 'colored',
            transition: Slide,
            autoClose: 500,
        });
    } else if (msgType === 'warn') { 
        showMSG =  toast.info(message, {
            position: 'top-right',
            theme: 'colored',
            transition: Slide,
            autoClose: 500,
        });
    } else { 
        showMSG =  toast(message, {
            position: 'top-right',
            theme: 'colored',
            transition: Slide,
            autoClose: 500,
        });
    }   
    return showMSG;
};

export default displayMSG;
import { 
    LOGOUT,
    ADD_CITY,
    GET_CITY,
    UPDATE_CITY,
    GET_ALL_CITIES,
    DELETE_CITY,
    GET_SELECTED_STATES,
    GET_SELECTED_DISTRICTS,
} from "../types";

const initialState = {
    allCities: null,
    addCityResponse: null,
    city: null,
    updateCityResponse: null,    
    deleteCityResponse: null,
    allSelectedStates: null,
    allSelectedDistricts: null,
};

export default function city(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_CITIES:
            return {
                ...state,
                allCities: payload,
            };
        case ADD_CITY:
            return {
                ...state,
                addCityResponse: payload,
            };
        case GET_CITY: 
            return {
                ...state,
                city: payload,
            };
        case UPDATE_CITY:
            return {
                ...state,
                updateCityResponse: payload,
            };
        case DELETE_CITY:
            return {
                ...state,
                deleteCityResponse: payload,
            };
        case GET_SELECTED_STATES:
            return {
                ...state,
                allSelectedStates: payload,
            };
        case GET_SELECTED_DISTRICTS:
            return {
                ...state,
                allSelectedDistricts: payload,
            };
        default:
            return state;
    }
}


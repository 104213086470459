import { 
    LOGOUT,
    ADD_CATEGORY,
    GET_CATEGORY,
    UPDATE_CATEGORY,
    GET_ALL_CATEGORIES,
    DELETE_CATEGORY,
} from "../types";

const initialState = {
    allCategories: null,
    addCategoryResponse: null,
    category: null,
    updateCategoryResponse: null,    
    deleteCategoryResponse: null,
};

export default function category(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_CATEGORIES:
            return {
                ...state,
                allCategories: payload,
            };
        case ADD_CATEGORY:
            return {
                ...state,
                addCategoryResponse: payload,
            };
        case GET_CATEGORY: 
            return {
                ...state,
                category: payload,
            };
        case UPDATE_CATEGORY:
            return {
                ...state,
                updateCategoryResponse: payload,
            };
        case DELETE_CATEGORY:
            return {
                ...state,
                deleteCategoryResponse: payload,
            };
        default:
            return state;
    }
}


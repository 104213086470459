import { 
    LOGOUT,
    ADD_AGREEMENT_TYPE,
    GET_AGREEMENT_TYPE,
    UPDATE_AGREEMENT_TYPE,
    GET_ALL_AGREEMENT_TYPES,
    DELETE_AGREEMENT_TYPE,
} from "../types";

const initialState = {
    allAgreementTypes: null,
    addAgreementTypeResponse: null,
    agreementType: null,
    updateAgreementTypeResponse: null,    
    deleteAgreementTypeResponse: null,
};

export default function agreementType(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_AGREEMENT_TYPES:
            return {
                ...state,
                allAgreementTypes: payload,
            };
        case ADD_AGREEMENT_TYPE:
            return {
                ...state,
                addAgreementTypeResponse: payload,
            };
        case GET_AGREEMENT_TYPE: 
            return {
                ...state,
                agreementType: payload,
            };
        case UPDATE_AGREEMENT_TYPE:
            return {
                ...state,
                updateAgreementTypeResponse: payload,
            };
        case DELETE_AGREEMENT_TYPE:
            return {
                ...state,
                deleteAgreementTypeResponse: payload,
            };
        default:
            return state;
    }
}


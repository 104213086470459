import { 
    LOGOUT,
    ADD_DEPARTMENT,
    GET_DEPARTMENT,
    UPDATE_DEPARTMENT,
    GET_ALL_DEPARTMENTS,
    DELETE_DEPARTMENT,
} from "../types";

const initialState = {
    allDepartments: null,
    addDepartmentResponse: null,
    department: null,
    updateDepartmentResponse: null,    
    deleteDepartmentResponse: null,
};

export default function department(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_DEPARTMENTS:
            return {
                ...state,
                allDepartments: payload,
            };
        case ADD_DEPARTMENT:
            return {
                ...state,
                addDepartmentResponse: payload,
            };
        case GET_DEPARTMENT: 
            return {
                ...state,
                department: payload,
            };
        case UPDATE_DEPARTMENT:
            return {
                ...state,
                updateDepartmentResponse: payload,
            };
        case DELETE_DEPARTMENT:
            return {
                ...state,
                deleteDepartmentResponse: payload,
            };
        default:
            return state;
    }
}


import { 
    LOGOUT,
    ADD_USER,
    GET_USER,
    UPDATE_USER,
    GET_ALL_USERS,
    DELETE_USER,
    GET_SELECTED_DEPARTMENTS,
} from "../types";

const initialState = {
    allUsers: null,
    addUserResponse: null,
    user: null,
    updateUserResponse: null,    
    deleteUserResponse: null,
    allSelectedDepartments: null,
};

export default function user(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_USERS:
            return {
                ...state,
                allUsers: payload,
            };
        case ADD_USER:
            return {
                ...state,
                addUserResponse: payload,
            };
        case GET_USER: 
            return {
                ...state,
                user: payload,
            };
        case UPDATE_USER:
            return {
                ...state,
                updateUserResponse: payload,
            };
        case DELETE_USER:
            return {
                ...state,
                deleteUserResponse: payload,
            };
        case GET_SELECTED_DEPARTMENTS:
            return {
                ...state,
                allSelectedDepartments: payload,
            };
        default:
            return state;
    }
}


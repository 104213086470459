import { 
    LOGOUT,
    ADD_REGION,
    GET_REGION,
    UPDATE_REGION,
    GET_ALL_REGIONS,
    DELETE_REGION,
} from "../types";

const initialState = {
    allRegions: null,
    addRegionResponse: null,
    region: null,
    updateRegionResponse: null,    
    deleteRegionResponse: null,
};

export default function region(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_REGIONS:
            return {
                ...state,
                allRegions: payload,
            };
        case ADD_REGION:
            return {
                ...state,
                addRegionResponse: payload,
            };
        case GET_REGION: 
            return {
                ...state,
                region: payload,
            };
        case UPDATE_REGION:
            return {
                ...state,
                updateRegionResponse: payload,
            };
        case DELETE_REGION:
            return {
                ...state,
                deleteRegionResponse: payload,
            };
        default:
            return state;
    }
}


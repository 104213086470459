import { 
    LOGOUT,
    ADD_TASK,
    GET_TASK,
    UPDATE_TASK,
    GET_ALL_TASKS,
    DELETE_TASK,
} from "../types";

const initialState = {
    allTasks: null,
    addTaskResponse: null,
    task: null,
    updateTaskResponse: null,    
    deleteTaskResponse: null,
};

export default function task(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_TASKS:
            return {
                ...state,
                allTasks: payload,
            };
        case ADD_TASK:
            return {
                ...state,
                addTaskResponse: payload,
            };
        case GET_TASK: 
            return {
                ...state,
                task: payload,
            };
        case UPDATE_TASK:
            return {
                ...state,
                updateTaskResponse: payload,
            };
        case DELETE_TASK:
            return {
                ...state,
                deleteTaskResponse: payload,
            };
        default:
            return state;
    }
}


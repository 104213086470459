import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';


// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ element }) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    // console.log("PROTECTED-ROUTE==",isAuthenticated);
    return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
import { configureStore } from '@reduxjs/toolkit';
// import authSlice from './auth/authSlice';
import NotesReducer from './apps/notes/NotesSlice';
import CustomizerReducer from './customizer/CustomizerSlice';
import ChatsReducer from './apps/chat/ChatSlice';
import ContactsReducer from './apps/contacts/ContactSlice';
import EmailReducer from './apps/email/EmailSlice';
import TicketReducer from './apps/ticket/TicketSlice';
import auth from './reducers/auth';
import branch from './reducers/branch';
import department from './reducers/department';
import region from './reducers/region';
import task from './reducers/task';
import propertyType from './reducers/propertyType';
import propertyStatus from './reducers/propertyStatus';
import category from './reducers/category';
import purpose from './reducers/purpose';
import agreementType from './reducers/agreementType';
import source from './reducers/source';
import escalation from './reducers/escalation';
import currentStatus from './reducers/currentStatus';
import district from './reducers/district';
import city from './reducers/city';
import location from './reducers/location';
import user from './reducers/user';


export const store = configureStore({
  reducer: {
    // auth: authSlice,
    customizer: CustomizerReducer,
    notesReducer: NotesReducer,
    chatReducer: ChatsReducer,
    contactsReducer: ContactsReducer,
    emailReducer: EmailReducer,
    ticketReducer: TicketReducer,
    auth,
    branch,
    department,
    region,
    task,
    propertyType,
    propertyStatus,
    category,
    purpose,
    agreementType,
    source,
    escalation,
    currentStatus,
    district,
    city,
    location,
    user,
  },
});

export default store;

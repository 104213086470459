import { 
    LOGOUT,
    ADD_PROPERTY_TYPE,
    GET_PROPERTY_TYPE,
    UPDATE_PROPERTY_TYPE,
    GET_ALL_PROPERTY_TYPES,
    DELETE_PROPERTY_TYPE,
} from "../types";

const initialState = {
    allPropertyTypes: null,
    addPropertyTypeResponse: null,
    propertyType: null,
    updatePropertyTypeResponse: null,    
    deletePropertyTypeResponse: null,
};

export default function propertyType(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_PROPERTY_TYPES:
            return {
                ...state,
                allPropertyTypes: payload,
            };
        case ADD_PROPERTY_TYPE:
            return {
                ...state,
                addPropertyTypeResponse: payload,
            };
        case GET_PROPERTY_TYPE: 
            return {
                ...state,
                propertyType: payload,
            };
        case UPDATE_PROPERTY_TYPE:
            return {
                ...state,
                updatePropertyTypeResponse: payload,
            };
        case DELETE_PROPERTY_TYPE:
            return {
                ...state,
                deletePropertyTypeResponse: payload,
            };
        default:
            return state;
    }
}


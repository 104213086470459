import { 
    LOGOUT,
    ADD_CURRENT_STATUS,
    GET_CURRENT_STATUS,
    UPDATE_CURRENT_STATUS,
    GET_ALL_CURRENT_STATUS,
    DELETE_CURRENT_STATUS,
} from "../types";

const initialState = {
    allCurrentStatus: null,
    addCurrentStatusResponse: null,
    currentStatus: null,
    updateCurrentStatusResponse: null,    
    deleteCurrentStatusResponse: null,
};

export default function currentStatus(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_CURRENT_STATUS:
            return {
                ...state,
                allCurrentStatus: payload,
            };
        case ADD_CURRENT_STATUS:
            return {
                ...state,
                addCurrentStatusResponse: payload,
            };
        case GET_CURRENT_STATUS: 
            return {
                ...state,
                currentStatus: payload,
            };
        case UPDATE_CURRENT_STATUS:
            return {
                ...state,
                updateCurrentStatusResponse: payload,
            };
        case DELETE_CURRENT_STATUS:
            return {
                ...state,
                deleteCurrentStatusResponse: payload,
            };
        default:
            return state;
    }
}


import { 
    LOGOUT,
    ADD_PURPOSE,
    GET_PURPOSE,
    UPDATE_PURPOSE,
    GET_ALL_PURPOSES,
    DELETE_PURPOSE,
} from "../types";

const initialState = {
    allPurposes: null,
    addPurposeResponse: null,
    purpose: null,
    updatePurposeResponse: null,    
    deletePurposeResponse: null,
};

export default function purpose(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_PURPOSES:
            return {
                ...state,
                allPurposes: payload,
            };
        case ADD_PURPOSE:
            return {
                ...state,
                addPurposeResponse: payload,
            };
        case GET_PURPOSE: 
            return {
                ...state,
                purposes: payload,
            };
        case UPDATE_PURPOSE:
            return {
                ...state,
                updatePurposesResponse: payload,
            };
        case DELETE_PURPOSE:
            return {
                ...state,
                deletePurposesResponse: payload,
            };
        default:
            return state;
    }
}


import { 
    LOGOUT,
    ADD_ESCALATION,
    GET_ESCALATION,
    UPDATE_ESCALATION,
    GET_ALL_ESCALATIONS,
    DELETE_ESCALATION,
} from "../types";

const initialState = {
    allEscalations: null,
    addEscalationResponse: null,
    escalation: null,
    updateEscalationResponse: null,    
    deleteEscalationResponse: null,
};

export default function escalation(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGOUT: 
            return {
                ...initialState,
            };
        case GET_ALL_ESCALATIONS:
            return {
                ...state,
                allEscalations: payload,
            };
        case ADD_ESCALATION:
            return {
                ...state,
                addEscalationResponse: payload,
            };
        case GET_ESCALATION: 
            return {
                ...state,
                escalation: payload,
            };
        case UPDATE_ESCALATION:
            return {
                ...state,
                updateEscalationResponse: payload,
            };
        case DELETE_ESCALATION:
            return {
                ...state,
                deleteEscalationResponse: payload,
            };
        default:
            return state;
    }
}


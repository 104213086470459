export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";

export const CHANGE_TRANSFER_PASSWORD = "CHANGE_TRANSFER_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES";
export const GET_BRANCH = "GET_BRANCH";
export const ADD_BRANCH = "ADD_BRANCH";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const DELETE_BRANCH = "DELETE_BRANCH";

export const GET_ALL_DEPARTMENTS = "GET_ALL_DEPARTMENTS";
export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";

export const GET_ALL_REGIONS = "GET_ALL_REGIONS";
export const GET_REGION = "GET_REGION";
export const ADD_REGION = "ADD_REGION";
export const UPDATE_REGION = "UPDATE_REGION";
export const DELETE_REGION = "DELETE_REGION";

export const GET_ALL_TASKS = "GET_ALL_TASKS";
export const GET_TASK = "GET_TASK";
export const ADD_TASK = "ADD_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";

export const GET_ALL_PROPERTY_TYPES = "GET_ALL_PROPERTY_TYPES";
export const GET_PROPERTY_TYPE = "GET_PROPERTY_TYPE";
export const ADD_PROPERTY_TYPE = "ADD_PROPERTY_TYPE";
export const UPDATE_PROPERTY_TYPE = "UPDATE_PROPERTY_TYPE";
export const DELETE_PROPERTY_TYPE = "DELETE_PROPERTY_TYPE";

export const GET_ALL_PROPERTY_STATUS = "GET_ALL_PROPERTY_STATUS";
export const GET_PROPERTY_STATUS= "GET_PROPERTY_STATUS";
export const ADD_PROPERTY_STATUS= "ADD_PROPERTY_STATUS";
export const UPDATE_PROPERTY_STATUS= "UPDATE_PROPERTY_STATUS";
export const DELETE_PROPERTY_STATUS= "DELETE_PROPERTY_STATUS";

export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

export const GET_ALL_PURPOSES = "GET_ALL_PURPOSES";
export const GET_PURPOSE = "GET_PURPOSE";
export const ADD_PURPOSE = "ADD_PURPOSE";
export const UPDATE_PURPOSE = "UPDATE_PURPOSE";
export const DELETE_PURPOSE = "DELETE_PURPOSE";

export const GET_ALL_AGREEMENT_TYPES = "GET_ALL_AGREEMENT_TYPES";
export const GET_AGREEMENT_TYPE = "GET_AGREEMENT_TYPE";
export const ADD_AGREEMENT_TYPE = "ADD_AGREEMENT_TYPE";
export const UPDATE_AGREEMENT_TYPE = "UPDATE_AGREEMENT_TYPE";
export const DELETE_AGREEMENT_TYPE = "DELETE_AGREEMENT_TYPE";

export const GET_ALL_SOURCES = "GET_ALL_SOURCES";
export const GET_SOURCE = "GET_SOURCE";
export const ADD_SOURCE = "ADD_SOURCE";
export const UPDATE_SOURCE = "UPDATE_SOURCE";
export const DELETE_SOURCE = "DELETE_SOURCE";

export const GET_ALL_ESCALATIONS = "GET_ALL_ESCALATIONS";
export const GET_ESCALATION = "GET_ESCALATION";
export const ADD_ESCALATION = "ADD_ESCALATION";
export const UPDATE_ESCALATION = "UPDATE_ESCALATION";
export const DELETE_ESCALATION = "DELETE_ESCALATION";

export const GET_ALL_CURRENT_STATUS = "GET_ALL_CURRENT_STATUS";
export const GET_CURRENT_STATUS= "GET_CURRENT_STATUS";
export const ADD_CURRENT_STATUS= "ADD_CURRENT_STATUS";
export const UPDATE_CURRENT_STATUS= "UPDATE_CURRENT_STATUS";
export const DELETE_CURRENT_STATUS= "DELETE_CURRENT_STATUS";

export const GET_ALL_DISTRICTS = "GET_ALL_DISTRICTS";
export const GET_DISTRICT = "GET_DISTRICT";
export const ADD_DISTRICT = "ADD_DISTRICT";
export const UPDATE_DISTRICT = "UPDATE_DISTRICT";
export const DELETE_DISTRICT = "DELETE_DISTRICT";
export const GET_SELECTED_STATES = "GET_SELECTED_STATES";

export const GET_ALL_CITIES = "GET_ALL_CITIES";
export const GET_CITY = "GET_CITY";
export const ADD_CITY = "ADD_CITY";
export const UPDATE_CITY = "UPDATE_CITY";
export const DELETE_CITY = "DELETE_CITY";
export const GET_SELECTED_DISTRICTS = "GET_SELECTED_DISTRICTS";

export const GET_ALL_LOCATIONS = "GET_ALL_LOCATIONS";
export const GET_LOCATION = "GET_LOCATION";
export const ADD_LOCATION = "ADD_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const GET_SELECTED_CITIES = "GET_SELECTED_CITIES";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER = "GET_USER";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_SELECTED_DEPARTMENTS = "GET_SELECTED_DEPARTMENTS";
